.SupplierBadge {
	font-size: 1.6rem;
	border: 1px solid #DCDCDC;
	border-radius: 1.2rem;
	display: inline-block;
	padding: 0.5rem 1rem;

	.supplier__code {
		//font-weight: 500;
		color: #858585;
	}

	.supplier__name {
		//font-weight: 500;
		color: #333333;
		margin-left: 1rem;
	}
}
