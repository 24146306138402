.submission-image {
    position: relative;
    overflow: visible !important;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: inherit;

        &.hide {
            display: none;
        }
    }

    .download-btn,
    .upload-btn {
        position: absolute;
        display: none;
        border-radius: 5px;
        padding: 0.5rem;
        outline: 0;
        border: 1px solid;
        cursor: pointer;
        color: #434343;
        text-decoration: none;
        font-family: 'Oswald', sans-serif;
        font-size: 1.25rem;
        background-color: rgba(255, 255, 255, 0.85);
        margin: 0;

        svg[data-prefix=fas] {
            margin-left: 0.5rem;
        }

        &:hover {
            color: #000;
            background-color: rgba(255, 255, 255, 0.95);
        }

        &.show {
            display: block;
        }
    }

    .download-btn {
        top: 1rem;
        right: 1rem;
    }
    .upload-btn {
        bottom: 1rem;
        right: 1rem;
    }


    &:hover {
        .download-btn,
        .upload-btn {
            display: block;
        }
    }
}

.submission-confirm {
    margin-top: 1rem;
    text-align: center;
    width: 100%;

    button {
        margin-left: 0.5rem;
        cursor: pointer;
    }
}
