.Registration-totals {
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  padding: 16px;
  border-radius: 10px;
  margin: 0 0 1rem 0;
  background: #fafafa;
  border: 1px solid #dadada;
  font-weight: 600;

  .Registration-totals__title {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-style: italic;
    color: #5A5A5A;
  }
  
  .Registration-totals__content {
    display: flex;
    flex-flow: row-reverse nowrap;
    gap: 1rem;
  }

  table {
    border-collapse: collapse;
    
    th {
      text-align: right;
      padding-right: 1.35rem;
    }

    td {
      font-weight: 700;
    }

    .total td,
    .total th {
      border-top: 1px solid #eaeaea;
    
    }
  }
  
  .circle-chart__circle {
    animation: circle-chart-fill 2s reverse; /* 1 */ 
    transform: rotate(-90deg); /* 2, 3 */
    transform-origin: center; /* 4 */
  }

  .circle-chart__percent {
    color: red;
  }
}