.TradeFairRegistrations {
    width: 100%;
    height: 100vh;
    overflow: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    --snackbar-width: 40rem;

    .totals_container {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        p {
            // font-size: 1.8rem;
            // font-weight: 500;
            margin: 0;
        }
    }

    .tfr-header {
        display: flex;
        flex-flow: row nowrap;
        gap: 1.5rem;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .return-link {
            color: #6D6D6D;
            
            &:hover,
            &:focus-visible {
                color: #434343;
            }
        }

        .refresh-data {
            color: #9f9f9f;
            margin-left: auto;
            
            button {
                margin-left: 1rem;
                display: inline-block;
                border: 0;
                outline: 0;
                padding: 0.65rem;
                min-width: 2rem;
                min-height: 2rem;
                aspect-ratio: 1/1;
                cursor: pointer;
                background-color: #f9f9f9;
                color: #9f9f9f;
                border: 1px solid;
                border-radius: 2.35rem;

                &:hover,
                &:focus-visible {
                    color: #dadada;
                    border-color: #343434;
                    background-color: #343434;
                }
            }
        }
    }

    .tfr-filter {
        border: 1px solid #dadada;
        background-color: #fafafa;
        border-radius: 0.65rem;
        padding: 1.5rem;
        width: 100%;
        margin: 0.5rem 0 2.5rem;

        input {
            outline: 0;
            padding: 1rem 1.5rem;
            border-radius: 0.8rem;
            border: 1px solid #C6C6C6;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.4rem;
            width: 100%;
            outline: none;
            margin: 0;

            &:focus {
                border-color: #555555;
            }
        }
    }

    .tfr-snackbar {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        transition: all 0.2s ease-in-out;
        overflow: auto;
        box-shadow: 0 0 1rem 0.35rem rgba(0, 0, 0, 0.3);
        background-color: white;
        z-index: 500;

        &.active {
            min-width: var(--snackbar-width);
            max-width: var(--snackbar-width);
        }

        &.inactive {
            min-width: 0;
            max-width: 0;
        }

        .tfr-snackbar-content {
            position: relative;
            padding: 1.5rem;
            min-width: var(--snackbar-width);

            h3 {
                font-size: 1.8rem;
            }

            h4 {
                font-size: 1.5rem;
            }

            .tfr-registration-info {
                .tfr-info-block {
                    margin-bottom: 1rem;

                    .tfr-info-label {
                        font-size: 1.2rem;
                        font-weight: 300;
                        text-transform: uppercase;
                    }

                    .tfr-info-value {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }

                .TradeFairAttendanceBlock {
                    margin-top: 0.65rem;
                }

                .attendees {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    span {
                        flex: 1;
                    }
                }
            }

            .tfr-snackbar-registrations {
                display: flex;
                flex-flow: column-reverse nowrap;
                justify-content: flex-start;
                align-items: stretch;
            }

            .tfr-snackbar-reg {
                position: relative;
                background-color: #F9F9F9;
                border: 1px solid #DADADA;
                padding: 0.65rem 1rem;
                border-radius: 0.35rem;
                margin-bottom: 1rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;

                &:hover {
                    background-color: #EAEAEA;
                }

                .TradeFairAttendanceBlock {
                    margin-top: 0.35rem;
                    margin-bottom: 0.65rem;
                }

                .tfr-snackbar-reg-content {
                    flex: 1;
                }

                .tfr-snackbar-reg-actions {
                    position: absolute;
                    top: 0;
                    right: 0;
                    flex: 0 0 3rem;

                    .tfr-snackbar-reg-remove {
                        width: 2.8rem;
                        height: 2.8rem;
                        background: transparent;
                        border: 0;
                        outline: 0;
                        cursor: pointer;
                        color: #df3434;
                        transition: opacity 0.15s ease-in-out;
                        opacity: 0;
                        padding: 0.65rem;
                        border-radius: 3rem;

                        &:hover {
                            color: #b82323;
                        }
                    }
                }

                &:hover {
                    .tfr-snackbar-reg-actions {
                        .tfr-snackbar-reg-remove {
                            opacity: 1;
                        }
                    }
                }

                .tfr-snackbar-reg-ts {
                    display: block;
                    color: #545454;
                    font-size: 0.85rem;
                    margin-bottom: 0.25rem;
                    font-size: 1.35rem !important;
                    font-weight: 300;
                }

                .tfr-snackbar-reg-attendees {
                    display: flex;
                    flex-flow: row nowrap;
                    color: #343434;
                    font-size: 1.35rem;
                    font-size: 1.8rem !important;

                    span {
                        flex: 1;
                        text-align: center;

                        .value {
                            display: block;
                            font-weight: 700;
                        }

                        .label {
                            display: block;
                            font-weight: 400;
                            text-transform: none;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        .tfr-snackbar-close {
            position: absolute;
            top: 1rem;
            right: 0.65rem;
            cursor: pointer;
            outline: 0;
            border: 0;
            background-color: transparent;
            font-size: 1.85rem;
            color: #434343;

            &:hover {
                color: black;
            }
        }
    }

    .tfr-registration {
        // border-radius: 0.65rem;
        // padding: 0.65rem;
        // border: 1px solid #DADADA;
        // background-color: #dadada;
        // border-radius: 0.35rem;
        margin-bottom: 0.65rem;

        form {
            width: 100%;

            input {
                width: 100%;
                outline: 0;
                padding: 1rem 1.5rem;
                border-radius: 0.8rem;
                border: 1px solid #C6C6C6;
                font-family: 'Open Sans', sans-serif;
                font-size: 1.4rem;
                width: 100%;
                outline: none;
                margin: 0 0 0.65rem;

                &:focus {
                    border-color: #555555;
                }
            }

            div + div {
                margin-top: 0.65rem;
            }

            .form-actions {
                margin-top: 1rem;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                align-items: center;
                gap: 0.65rem;

                button {
                    background-color: #434343;
                    border: 0;
                    border-radius: 1.2rem;
                    color: white;
                    padding: 0.8rem 2rem;
                    font-family: "Oswald", sans-serif;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    // box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.3);
                    cursor: pointer;
                    transition: 0.2s ease all;
                    position: relative;
                    margin: 0;
                    display: inline-block;
                    text-decoration: none;
                    cursor: pointer;
                    outline: 0;
                    
                    .Spinner {
                        color: white;
                        --color: white;
                    }

                    &:hover {
                        border-color: #555555;
                    }

                    &[type=reset] {
                        background-color: transparent;
                        color: #434343;
                    }
                }
            }
        }

        .tfr-registration-error {
            color: red;
            line-height: 2.25rem;
            font-size: 1.25rem;
        }
    }

    .List {
        .list__wrapper {
            display: grid;
            overflow-y: visible;
            background: var(--visits-color-bg);
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

            .list__header {
                grid-template-columns: subgrid;
                max-width: 100%;
                overflow: hidden;
                padding: 1rem 0 0;
                margin-top: -1rem;
                position: sticky;
                top: -1rem;
                z-index: 100;
                background-color: var(--visits-color-bg);

                .header__column {
                    padding: var(--visits-padding);
                    margin: 0;
                    overflow: hidden;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    .header__content {
                        min-width: auto;
                        min-width: 100%;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        overflow: auto;

                        .left {
                            margin-right: 0.35rem;
                        }

                        .right {
                            margin-left: auto;

                            .header__filter {
                                padding: 0.4rem;
                            }
                        }
                    }
                }
            }

            .list__body {
                grid-template-columns: subgrid;

                .list__item {
                    max-width: 100%;
                    grid-template-columns: subgrid;

                    .open_order {
                        @media(max-width: 67rem) {
                            padding-left: 2.5rem;
                        }
                    }

                    &:hover {
                        background: var(--visits-color-bg-hover);
                        background: #dadada;
                    }

                    .Dropdown .Dropdown__trigger {
                        width: min-content;
                        background: transparent;
                        color: black;
                    }
                }
            }
        }
    }

    .List .list__wrapper .list__body .list__item:hover .CharlesTitle {
        margin-top: 6rem;
    }

    .visits-wrapper {
        width: 100%;
    }

    .visits-header {
        background-color: var(--visits-color-bg);
        padding: var(--visits-padding);
        border-radius: var(--visits-border-radius);
    }

    .visits-holder {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--visits-gap);
        flex: 1;
        overflow: hidden;
    }

    .visits-list {
        flex: 1;
        min-width: 80rem;
        // background: var(--visits-color-bg);
        padding: var(--visits-padding);
        border-radius: var(--visits-border-radius);
        flex: 1;
        overflow: auto;
        align-self: stretch;
    }
}
