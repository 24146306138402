.TradeFairInfoBlock {
    border-radius: 1rem;
    padding: 1rem;
    background: #dadada;
    width: 25rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    color: inherit;
    font-size: 1.35rem;
    
    &:hover {
        background: #c0c0c0;
    }
    
    .info-title {
        font-size: 1.65rem;
        margin-top: 0;
        margin-bottom: 0.65rem;
    }
}
