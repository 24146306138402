@import './../../../index.scss';

.ApprovalActions {
	margin-top: 1rem;

	.action {
		margin: 1.5rem 0.5rem 1.5rem 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		.reasons {
			font-size: 1.6rem;
			color: grey;
			margin: 0.5rem 0 0 0.5rem;
			min-width: 40rem;
			font-family: 'Open Sans';

			.form__control {
				margin: 0.5rem 0;
				display: flex;

				&:last-of-type {
					input[type=checkbox] {
						margin-top: 1.2rem;
					}
				}

				label {
					width: 100%;
					font-weight: 400;
					display: flex;
					align-items: flex-start;
				}

				input[type=checkbox] {
					margin-top: 0.5rem;
				}

				.label--content {
					width: 100%;
					margin-left: 0.5rem;
				}

				textarea {
					font-size: 1.6rem;
					font-family: 'Open Sans', sans-serif;
					color: grey;
					font-weight: 400;
					padding: 0.5rem 1rem;
					border: 1px solid #D8D7D7;
					border-radius: 1.2rem;
					height: 3.6rem;
					width: 100%;
					resize: none;
					transition: 0.3s ease height;

					&::placeholder {
						font-style: italic;
						
					}

					&.active {
						height: 10rem;
					}
				}
			}
		}
	}

	.warnings {
		list-style: none;
		font-size: 1.6rem;
		font-weight: 300;
		padding: 0;
		margin: 0.5rem 0 0 1rem;
		color: $color-red-0;
	}
}
