.BannersInput {
	width: 100%;

	.item {
		padding: 1rem;
		border: 1px solid #D0D0D0;
		border-radius: 0.6rem;
		background-color: #e8e8e8;

		&:not(:last-of-type) {
			margin-bottom: 1rem;
		}

		.form__field {
			padding: 0.5rem 0;
		}

		.delete {
			margin-top: 1rem;
		}
	}

	.actions {
		margin-top: 1rem;
	}
}