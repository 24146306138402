@import './../../../index.scss';

.Campaign {
	width: 100%;
	max-width: 80rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1rem;
	margin-bottom: 6rem;

	h1 {
		font-size: 4rem;
		text-transform: uppercase;
		color: #333;
		border-bottom: 0.3rem solid #C1AD76;
		margin: 0 0 2rem 0;
		padding: 0;
		font-weight: 400;
	}

	&>.actions {
		display: flex;
		width: 100%;

		.Button {
			margin-right: 1rem;
		}
	}

	.save-message {
		font-family: $font-main;
		margin-bottom: 2rem;
		font-size: 1.6rem;
		
		&.error {
			color: $color-red-0;
		}

		&.success {
			color: $color-green;
		}
	}

	.campaign-legacy {
		font-family: $font-main;
		margin-bottom: 2rem;
		font-size: 1.6rem;
		width: 100%;
		font-weight: 600;
		color: $color-black-2;
		font-style: italic;

		svg {
			margin-right: 1rem;
		}
	}

	.FileInput {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;

		.FileUpload {
			width: 100%;
		}

		.file-input--preview {
			max-height: 20rem;
		}
	}

	
}

.DatePicker .calendar__days .calendar__day {
	position: relative;

	&.start-highlight:after {
		content: '';
		position: absolute;
		top: 0.3rem;
		right: 0;
		bottom: 0.3rem;
		left: 0.3rem;
		border-top-left-radius: 0.6rem;
		border-bottom-left-radius: 0.6rem;
		background-color: rgba($color-main-0, 0.8);
		border-top: 1px solid $color-main-0;
		border-bottom: 1px solid $color-main-0;
		border-left: 1px solid $color-main-0;
	}

	&.end-highlight:after {
		content: '';
		position: absolute;
		top: 0.3rem;
		right: 0.3rem;
		bottom: 0.3rem;
		left: 0;
		border-top-right-radius: 0.6rem;
		border-bottom-right-radius: 0.6rem;
		background-color: rgba($color-main-0, 0.8);
		border-top: 1px solid $color-main-0;
		border-bottom: 1px solid $color-main-0;
		border-right: 1px solid $color-main-0;
	}

	&.inbetweeners-highlight:after {
		content: '';
		position: absolute;
		top: 0.3rem;
		right: 0;
		bottom: 0.3rem;
		left: 0;
		background-color: rgba($color-main-0, 0.5);
		border-radius: 0;
		border-top: 1px solid $color-main-0;
		border-bottom: 1px solid $color-main-0;
	}
}
