.Archive {
	max-width: 140rem;
	width: 100%;
	padding: 0 2rem 3rem 2rem;
	min-height: 100vh;

	h1, h2 {
		text-transform: uppercase;
		font-weight: 400;
		margin: 0;
	}

	h1 {
		font-size: 3.6rem;
		color: #333333;
		margin-top: 8rem;
	}

	h2 {
		font-size: 2.4rem;
		color: #858585;
		margin-top: -1rem;
	}

	.filters {
		width: 100%;
		margin: 2rem 0;
	}
	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			font-size: 4rem;
		}
	}

	.SubmissionStatusBadge {
		display: inline-flex;
		flex-direction: column;
		align-items: flex-start;

		.version__since {
			margin-left: 0;
			padding-left: 1rem;
		}

		.version__by {
			margin-left: 0;
		}

		.version__status {
			z-index: inherit;
		}
	}

	.Checkbox{
		white-space: nowrap;

		+ .Checkbox{
			margin-top: 1rem;
		}
	}

	span.change {
		font-size: 1.4rem;
		color: white;
		border-radius: 0.6rem;
		background-color: #333333;
		padding: 0.3rem 1rem;

		margin-right: 0.5rem;
		white-space: nowrap;

		&:last-of-type {
			margin-right: 0;
		}
	}

	span.change.category--text {
		background-color: #3D7DAB;
	}

	span.change.category--images {
		background-color: #9C3E77;
	}

	.List {
		margin-top: 5rem;

		.list__body .list__item {
			color: #707070;
			font-size: 1.8rem;
			text-transform: uppercase;
			font-weight: 400;


			span.change {
				font-size: 1.4rem;
				color: white;
				border-radius: 0.6rem;
				background-color: #333333;
				padding: 0.3rem 1rem;

				&.category {

					&--text {
						background-color: #3D7DAB;
					}

					&--images {
						background-color: #9C3E77;
					}
				}
			}

		}

	}
}

.header__filter-content {
	display: flex;
	flex-direction: column;

	.Checkbox label {
		display: flex;
		align-items: center;
	}

	.suppliersAsUser {
		margin-bottom: 1rem;
	}

	.SubmissionStatusBadge{
		margin: .3rem 0;
		font-family: 'Oswald', sans-serif;
		font-weight: 400;
	}

	span.change {
		font-size: 1.4rem;
		color: white;
		border-radius: 0.6rem;
		background-color: #333333;
		padding: .3rem 1rem;
		margin: .3rem 0;
		font-family: 'Oswald', sans-serif;
		font-weight: 400;
		text-transform: uppercase;

		&.category {

			&--text {
				background-color: #3D7DAB;
			}

			&--images {
				background-color: #9C3E77;
			}
		}
	}

	.DateRangeFilter{
		margin-top: 2rem;
	}
}
