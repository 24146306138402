@import './../../../index.scss';

.Campaigns {
	width: 100%;
	max-width: 100rem;

	> div {
		margin-bottom: 4rem;
	}

	h2 {
		text-transform: uppercase;
		font-size: 2rem;
		font-weight: 400;
		margin: 0 0 1rem 0;
	}

	.simulate {
		padding: 0.8rem 1.2rem;
		border: 1px solid lightgrey;
		border-radius: 1.2rem;

		.top {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 1rem;
			column-gap: 1rem;

			.DateTimeInput {
				width: auto;

				input {
					width: 20rem;
				}
			}

			.Checkbox {
				margin-left: 2rem;
			}
		}
	}

	.badge {
		position: relative;
		white-space: nowrap;
		color: white;
		background-color: #a8a8a8;
		padding: .4rem 1.3rem;

		border-radius: 2.2rem;
		border: 3px solid var(--background-color);

		+ .badge {
			margin-left: -1rem;
		}

		&.badge__banners{
			color: #cd5e83;
			background-color: #f3d2dd;
		}

		&.badge__documents{
			color: #d0414c;
			background-color: #e7ccce;

		}

		&.badge__articles{
			color: #3981a9;
			background-color: #c5d2db;
		}

		&.badge__filter{
			color: #ffc734;
			background-color: rgb(245, 238, 205);
		}

		&.badge__badge{
			color: #1c9462;
			background-color: #c6e8da;
		}


	}

	.item__cell.period {
		//text-transform: uppercase;

		svg {
			margin: 0 1rem;
		}
	}

	.activeDisplay {
		max-width: 4rem;
		display: flex;
		justify-content: center;

		&:after {
			content: '';
			background-color: transparent;
			height: 1rem;
			width: 1rem;
			border-radius: 1rem;
			box-sizing: border-box;
		}

		&.active:after {
			background-color: $color-green-2;
		}

		&.waiting:after {
			background-color: #E57A44;
		}

		&.inactive:after {
			border: 0.3rem solid #D3D3D3;
		}
	}

	.List .item__cell:not(:last-child){
		margin-right: 1.5rem
	}
}

.PortalModal.promo-page .modal__body {
	width: 80%;
	height: 80%;

	.modal__content {
		height: 100%;
		max-height: 100%;
		overflow-y: auto;
	}
	
	.CampaignsOverview, .CampaignDetail {
		min-height: inherit;
	}
}
