.Accounts {
	max-width: 120rem;
	width: 100%;
	color: #333333;
	display: flex;
	flex-direction: column;
	//align-items: center;
	margin-bottom: 8rem;

	.List {
		width: 100%;

		.list__wrapper {
			.list__body {
				font-weight: 400;

				.list__item {
					align-items: flex-start;

					.item__cell {
						min-height: 4.4rem;
						display: flex;
						align-items: center;

						&.supplier {
							.suppliers {
								display: flex;
								flex-direction: column;
								align-items: flex-start;

								.SupplierBadge {								
									&:not(:last-of-type) {
										margin-bottom: 1rem;
									}
								}
							}
						}

						&.email {
							svg {
								margin-right: 0.5rem;
								font-size: 2rem;
							}
						}

						.blocked {
							color: #777777;
						}
					}
				}
			}
		}		
	}

	.new-account {
		margin: 2rem 0 3rem 0;
	}
}
