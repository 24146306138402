.Account {
	width: 60rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 2rem 0 8rem 0;

	h1 {
		font-size: 3.6rem;
		text-transform: uppercase;
		color: #333;
		border-bottom: 0.3rem solid #C1AD76;
		margin: 0 0 2rem 0;
		padding: 0;
		font-weight: 400;
	}
	
	.DynamicForm {
		.form__section {
			h2 {
				color: #373735;
				font-size: 2.8rem;
				margin-bottom: 1rem;
			}
		}
	}

	.notification-blocked {
		font-size: 1.4rem;
		font-family: 'Open Sans', sans-serif;
		font-style: italic;
		margin-bottom: 1rem;
		font-weight: 600;
		color: rgb(255, 159, 28);
		display: flex;
		align-items: center;

		svg {
			font-size: 1.6rem;
			margin-right: 1rem;
		}
	}

	.no-items {
		font-size: 1.6rem;
		font-family: 'Open Sans', sans-serif;
		font-style: italic;
		color: #858585;
	}	

	.account__actions {
		margin: 2rem 0 2rem 2rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 0rem;
		min-width: 38rem;
	}

	.account__details {
		font-family: Oswald, sans-serif;

		.account__detail {
			display: grid;
			grid-template-areas: 'key value';
			grid-template-columns: 2rem 1fr;
			margin: 1.2rem 0;

			.detail__key {
				grid-area: key;
				text-transform:  uppercase;
				font-size: 1.6rem;
				font-weight: 500;
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				margin-right: 2rem;
				line-height: 4.5rem;
			}

			.detail__value {
				grid-area: value;
				padding: 1rem 2rem;
				font-size: 1.4rem;
				min-width: 38rem;

				&.suppliers {
					display: flex;
					flex-direction: column;
					
					.SupplierBadge {
						margin-bottom: 0.5rem;
					}
				}
			}

			svg {
				margin-right: 1rem;
			}

			&.blocked {
				margin: -1rem 0 0 0;

				.detail__value {
					text-align: center;
					padding: 0;
				}
			}
		}

		.detail--button {
			width: 100%;
		}
	}

	&.blocked {
		color: grey;
	}

	.actions {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		flex-wrap: wrap;
		
		.Button {
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
	}

	.sublogins {
		margin-top: 2rem;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.items {
			background-color: rgb(244, 244, 244);
			border-radius: 1.2rem;
			padding: 1rem;
			width: 100%;

			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.subaccount {
			font-size: 1.6rem;
			border: 1px solid #DCDCDC;
			border-radius: 1.2rem;
			padding: 1rem 2rem;
			background-color: white;

			display: flex;
			flex-direction: column;

			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 0;
			}

			.top {
				display: flex;

				.id {
					color: #858585;
					margin-right: 1rem;
				}
			}

			.email {
				font-family: 'Open Sans', sans-serif;
				font-size: 1.4rem;
			}
		}
	}

	.error, .submit__error {
		font-family: 'Open Sans', sans-serif;
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		color: #c2092b;

		svg {
			margin-right: 1rem;
		}
	}
}
