.SubmissionItem {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 1rem;
	width: 100%;

	.submission {
		width: 50rem;
		min-height: 10rem;
		border-radius: 1.2rem;
		border: 1px solid #D8D8D8;

		pre {
			font-family: inherit;
			margin: 0;
		}

		&.text {
			font-family: 'Open Sans', sans-serif;
			font-size: 1.6rem;
			font-weight: 400;
			padding: 0.8rem 1.2rem;
			background-color: #f8f8f8;
			color: #757575;
		}

		& .submission-image,
		&.image {
			overflow: hidden;
			position: relative;

			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}

	.info {
		margin-left: 1rem;
	}
}