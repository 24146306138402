.Approvals {
	max-width: 120rem;
	width: 100%;

	h1, h2 {
		text-transform: uppercase;
		font-weight: 400;
		margin: 0;
	}

	h1 {
		font-size: 3.6rem;
		color: #333333;
		margin-top: 8rem;
	}

	h2 {
		font-size: 2.4rem;
		color: #858585;
		margin-top: -1rem;
	}

	.filters {
		//height: 15rem;
		width: 100%;
		//background-color: lightgrey;
		margin: 2rem 0;
	}

	.message {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 2.4rem;
		font-weight: 300;

		span[role="img"] {
			font-size: 4rem;
		}
	}

	.List {
		.header__column {
			color: #333333;
			font-size: 2.4rem;
			font-weight: 400;

			&.changes {
				text-align: right;
			}
		}

		.list__body .list__item {
			color: #707070;
			font-size: 2.4rem;
			text-transform: uppercase;
			font-weight: 400;

			.image {
				width: 5rem;
				height: 5rem;
				margin: -1rem 0 -1rem -1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1rem;

				img {
					//display: none;
					width: 5rem;
					//height: 5rem;
				}
			}

			.customer-code {
				
			}

			.code-s {
				
			}

			.name {
				color: #333333;
			}

			.supplier {
				
			}

			.changes {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				span {
					font-size: 1.6rem;
					color: white;
					border-radius: 0.6rem;
					background-color: #333333;
					padding: 0.2rem 0.5rem;

					margin-right: 0.5rem;

					&:last-of-type {
						margin-right: 0;
					}
				}

				.category--text {
					background-color: #3D7DAB;
				}

				.category--images {
					background-color: #9C3E77;
				}
			}
		}
	}
}
