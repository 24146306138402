.ApprovalSection {
	margin-bottom: 5rem;
	width: 100%;
	
	h2, h3 {
		text-transform: uppercase;
		color: #333333;
		font-weight: 400;
		margin: 0 0 1rem 0;
	}
	
	h2 {
		font-size: 3rem;
	}

	h3 {
		font-size: 2.2rem;
	}

	.subsection {
		border: 1px solid #D8D8D8;
		padding: 1rem 1.5rem;
		border-radius: 0.6rem;
		background-color: #FCFCFC;
		margin-bottom: 2rem;
	}
	
	.no-active {
		font-size: 1.6rem;
		margin: 0 0 0 0.2rem;
		color: grey;
		//font-weight: 300;
		font-family: 'Open Sans';
	}
}
