.ArticlesInput {
	width: 100%;

	.add {
		margin-top: 1rem;
	}

	.articles {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 0.5rem;
		row-gap: 0.5rem;
		margin-bottom: 0.5rem;
				
		.article {
			padding: 0.5rem 1.5rem;
			border-radius: 100rem;
			background-color: #e8e8e8;
			min-width: 6rem;
			font-size: 1.4rem;
			width: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			user-select: none;

			.article__body {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				margin-right: 0.5rem;
			}

			.article__id {
				margin-right: 0.5rem;
				color: #6c757d;
			}

			.article__loading {
				width: 100%;
				height: 2.1rem;
				animation-duration: 1.8s;
				animation-fill-mode: forwards;
				animation-iteration-count: infinite;
				animation-name: suspenseShimmer;
				animation-timing-function: linear;
				background: #f6f7f8;
				background: linear-gradient(to right, #dfdfdf 8%, #d3d3d3 38%, #dfdfdf 54%);
				border-radius: 0.6rem;
			}

			.article__name {

			}

			.article__unknown {
				font-style: italic;
				color: #6c757d;
				font-weight: 300;
			}

			svg {
				color: #c2092b;
				cursor: pointer;
			}
		}
	}
}
