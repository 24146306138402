.TradeFairAttendanceBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    .attendance {
        flex: 1;
        text-align: center;
        
        .attendance-count {
            display: block;
            font-weight: 700;
            font-size: 1.8rem;
        }
        
        .attendance-label {
            display: block;
            font-weight: 400;
            text-transform: none;
            font-size: 1.2rem;
        }
    }
}
