@import './../../../index.scss';

.Campaigns {
	width: 100%;
	max-width: 100rem;

	> div {
		margin-bottom: 4rem;
	}

	.badge {
		position: relative;
		white-space: nowrap;
		color: white;
		background-color: #a8a8a8;
		padding: .4rem 1.3rem;

		border-radius: 2.2rem;
		border: 3px solid var(--background-color);

		+ .badge {
			margin-left: -1rem;
		}

		&.badge__articles{
			color: #3981a9;
			background-color: #c5d2db;
		}

		&.badge__badge{
			color: #1c9462;
			background-color: #c6e8da;
		}


	}

	.item__cell.period {
		//text-transform: uppercase;

		svg {
			margin: 0 1rem;
		}
	}

	.activeDisplay {
		max-width: 4rem;
		display: flex;
		justify-content: center;

		&:after {
			content: '';
			background-color: transparent;
			height: 1rem;
			width: 1rem;
			border-radius: 1rem;
			box-sizing: border-box;
		}

		&.active:after {
			background-color: $color-green-2;
		}

		&.waiting:after {
			background-color: #E57A44;
		}

		&.inactive:after {
			border: 0.3rem solid #D3D3D3;
		}
	}

	.List .item__cell:not(:last-child){
		margin-right: 1.5rem
	}
}
