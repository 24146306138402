* {
	box-sizing: border-box;

	&::selection {
		background-color: #333333;
		color: white;
	}
}

html, body {
	padding: 0;
	margin: 0;
}

html {
	font-size: 10px;
}

html {
	--width-nav: 32rem;
	--width-nav-collapsed: 6rem;
}

body {
	font-family: Oswald, sans-serif;
	color: #373735;
}

$color-black-2: #373735;
$font-heading: Oswald, sans-serif;
$font-main: 'Open Sans', sans-serif;
$color-main-0: #C1AD76;
$color-red: rgba(206, 63, 37, 1);
$color-red-0: #C1222F;
$color-red-1: #ff2149;
$color-red-2: #c2092b;
$color-green: rgba(0, 177, 106, 1);
$color-green-2: rgb(28, 148, 98);

.charles-toast {
	background-color: $color-black-2;
	color: white;

	.charles-toast__body {
		padding: 0 1rem;
		font-size: 1.4rem;
		font-family: $font-heading;
		font-weight: 300;
		max-width: 270px;
	}

	.charles-toast__progress {
		background: $color-main-0;
	}

	.Toastify__close-button {
		color: white;
	}

	&.Toastify__toast--success {
		background-color: $color-black-2;

		.charles-toast__progress {
			background: #189f27;
		}
	}

	&.Toastify__toast--error {
		background-color: $color-black-2;
		border-left: 1rem solid $color-red;
	}
}



.PortalModal {
	font-size: 1.6rem;

	.modal__text {
		padding: 1rem 1rem 2rem 1rem;
		font-family: 'Open Sans', sans-serif;
		font-size: 1.8rem;
	}
}
