.TradeFairsOverview {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    
    .list {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    }
}
