
.Search {
	border: 1px solid #ECECEC;
	border-radius: 1.2rem;
	padding: 2rem 2rem;
	margin: 0 0 2rem 0;
	width: 100%;
	font-size: 2rem;
	font-family: Oswald, sans-serif;
}
