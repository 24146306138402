@import './../../../index.scss';

.Approval {
	width: 100%;
	max-width: 120rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 8rem;
	position: relative;
	margin-top: 2rem;
	
	.title {
		text-transform: uppercase;
		margin: 0 0 4rem 0;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;


		// .supplier {
		// 	font-size: 1.8rem;
		// 	border: 1px solid #DCDCDC;
		// 	border-radius: 1.2rem;
		// 	display: inline-block;
		// 	padding: 0.5rem 1rem;

		// 	.supplier__code {
		// 		font-weight: 500;
		// 		color: #858585;
		// 	}

		// 	.supplier__name {
		// 		font-weight: 600;
		// 		color: #333333;
		// 		margin-left: 1rem;
		// 	}
		// }
	}

	.product-title {
		text-transform: uppercase;
		font-size: 3.6rem;
		font-weight: 500;
		margin: 0 2rem 0 0;

		.product-title__code {
			color: #858585;
		}

		.product-title__name {
			color: #333333;
			margin-left: 2rem;
			border-bottom: 0.3rem solid #C1AD76;
		}
	}

	.publish {
		font-size: 2rem;
		//width: 20rem

		.content {
			display: flex;
			align-items: center;
		}

		svg {
			margin-right: 1rem;
			width: 2rem;
			height: 2rem;
		}
	}

	.items-handled {
		font-size: 1.8rem;
		font-weight: 300;
		margin: 1rem 1rem 0.5rem 1rem;
		text-transform: uppercase;
		//color: #474747;

		&.red {
			color: $color-red-2;
		}
	}

	.error-in-progress {
		color: $color-red-2;
		font-size: 1.8rem;
		font-weight: 300;
		margin: 0.15rem 1rem 0.5rem 1rem;
	}
}
