.App {
	display: flex;
	height: 100%;
	//height: 100vh;
	//overflow-y: hidden;
	display: grid;
	grid-template-areas: 'nav main';
	grid-template-columns: var(--width-nav) auto;


	&.nav--collapsed {
		grid-template-columns: var(--width-nav-collapsed) auto;
	}

	.Navigation {
		grid-area: nav;
	}

	main {
		grid-area: main;
		position: relative;
		width: 100%;
		//margin-left: var(--width-nav);
		height: 100%;
		//overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
