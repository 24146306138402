.SubmissionStatusBadge {
	text-transform: uppercase;
	font-size: 1.4rem;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;

	.version__status, .version__since {
		border-radius: 0.6rem;
		padding: 0.3rem 1rem;
	}

	.version__status {
		color: white;
		border: 1px solid transparent;
		position: relative;
		z-index: 1;
		background-color: #333333;
		border-color: #333333;

		&.APPROVED {
			background-color: #1C9462;
			border-color: #1C9462;
		}

		&.SUBMITTED {
			background-color: #FBB42C;
			border-color: #FBB42C;
		}

		&.INVALID {
			background-color: #b8b8b8;
			border-color: #b8b8b8;
		}

		&.REJECTED {
			background-color: #ec5c5c;
			border-color: #ec5c5c;
		}

		&.TRANSLATE {
			background-color: #3373b8;
			border-color: #3373b8;
		}
	}

	.version__since {
		color: #333333;
		border: 1px solid #D8D8D8;
		background-color: #F8F8F8;
		padding-left: 2rem;
		margin-left: -1rem;
		position: relative;
		white-space: nowrap;
	}

	.version__by {
		color: #333333;
		font-weight: 300;
		margin-left: 0.8rem;
		font-size: 1.3rem;
		text-transform: none;

		.name {
			font-weight: 400;
		}
	}
}
